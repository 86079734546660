.main-menu {
  border-bottom: 1px solid @primary-color;
  .menu-container {
    display: flex;
    flex-direction: row;
    .header-menu {
      flex: 7
    }
    .account-menu {
      flex: 3;
      .account-menu-container {
        width: 130px;
        float: right;
        .account-menu-item {
          display: flex;
          height: 60px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            color: @grey-3;
          }
          p {
            font-size: 9px;
            color: @grey-3;
            margin-bottom: 0;
            margin-top: 5px
          }
        }
      }
    }
  }
}

.ant-menu-horizontal {
  border-bottom: none;
  height: 60px;
  line-height: 60px;
  .ant-menu-item {
    padding: 0 20px 0 20px !important;

    &:after {
      display: none;
    }

    .ant-menu-title-content {
      font-size: 16px;
      font-weight: 700;
      padding: 15px 0 14px 0;
      a {
        color: @grey-3;
        font-weight: 400;
        font-size: 14px;
      }
    }

    &:hover, &-selected {
      background: @darken-primary;
      a {
        color: @white !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-menu {
    display: none;
  }
}