.customer_delivery_registration_modal {
  padding: 20px 20px 5px;
  .btn_wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .ant-btn-lg {
      height: 44px;
      width: 170px;
      background: @primary-color;
      color: @white;
      font-weight: @bold;
      font-size: @md;
    }
  }
}
.form_wrapper {
  .tag_label (@color, @content, @mr: 5px, @pt: 4px) {
    display: inline-block;
    text-align: center;
    width: 26px;
    height: 16px;
    border-radius: 8px;
    font-size: 9px;
    background: @color;
    color: @white;
    padding-top: @pt;
    padding-right: 1px;
    margin-right: @mr;
    content: @content;
    font-family: Yu Gothic !important;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    .tag_label(@primary-color, '必須');
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: none;
  }
  .ant-form-item-label > label {
    margin-bottom: 0 !important;
  }
}
