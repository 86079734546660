.forgot_pass {
  width: 500px;
  padding: 70px 50px 70px 50px;
  margin-top: 198px;
  margin-bottom: 77px;
  background: #ffffff;

  &_logo {
    width: 100%;
    margin-bottom: 70px;
  }

  &_buttons {
    margin-top: 48px;
  }

  &_button_forgot {
    padding-right: 20px;
    button {
      span {
        font-size: 16px;
      }
    }
  }

  &_button_cancel {
    padding-left: 20px;

    button {
      background: @white;

      &:hover {
        background: @white;
      }

      &:active {
        background: @white;
      }

      span {
        color: @primary-color;
        font-size: 16px;
        font-weight: 700;
      }
    }

  }
}

@media screen and (max-width: 768px) {
  .forgot_pass {
    padding: 70px 15px 70px 15px;
    margin-top: 60px;
  }
}