.customer_delivery_setting {
  margin: 30px 0px;
  .customer_name {
    color: @primary-color;
  }
  .description_text {
    white-space: pre-line;
    font-size: @sm;
    font-weight: @medium;
    .customer_name {
      text-decoration: underline;
    }
  }
  table {
    border-collapse: collapse;
  }
  .table_wrapper {
    .table_head {
      margin-top: 20px;
      margin-bottom: 10px;
      .table_title {
        font-size: @xl;
        font-weight: @bold;
      }
    }
    .ant-table {
      border: none !important;
      background: none !important;
    }
    .list_table {
      border: 1px solid @grey;
      border-inline: none;
      padding-bottom: 20px;
      margin-bottom: 20px;
      .custom_table {
        margin: 0 !important;
        margin-bottom: 20px !important;
        .ant-table-thead {
          .ant-table-cell {
            background: none !important;
            font-size: @sm;
            font-weight: @bold;
            color: @black-2;
          }
          .ant-table-cell::before {
            display: none;
          }
        }
        .ant-table-tbody {
          .ant-table-cell {
            background: @white;
            font-size: @sm;
            font-weight: @medium;
            color: @black-2;
            vertical-align: baseline;
            .ant-form-item {
              margin-bottom: 0px !important;
            }
            .ant-input {
              height: 44px;
            }
          }
          .ant-table-row:has(.otokesakiCd_001) {
            .ant-table-selection-column {
              .ant-checkbox-wrapper {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .ant-btn-lg {
    height: 44px !important;
    background-color: @primary-color;
    border-color: @primary-color;
    color: @white;
    font-weight: @bold;
  }
  .w-170 {
    min-width: 170px;
    width: auto;
  }
  .mt-20 {
    margin-top: 20px;
  }
}

@media screen and (max-width: 390px) {
  .customer_delivery_setting {
    margin-top: 20px;
    margin-bottom: 30px;
    .description_text {
      font-size: @xs;
    }
    .table_wrapper {
      .table_head {
        margin-bottom: 20px;
      }
      .ant-table {
        border: none !important;
      }
    }
  }
}
