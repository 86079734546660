.customer-search-table {
  table {
    border-collapse: collapse;
  }
  .ant-table {
    border: none;
    background-color: white !important;
  }

  .ant-table-thead {
    background-color: white;
    .ant-table-cell {
      background-color: white !important;
      border: 1px @primary-color solid;
      color: @black-2;
    }
  }

  tbody:before {
    /* This doesn't work because of border-collapse */
    line-height:1em;
    content:"_";
    color:white;
    display:block;
  }

  .ant-table-tbody {
    .ant-table-cell {
      background: @white !important;
      border: 1px @gray-1 solid;
      padding: 0 !important;
      color: @black-2;
      .ant-divider {
        margin: 0 !important;
        border-color: @gray-1 !important;
      }
      .ant-row {
        padding: 5px;
      }
    }
  }

  .ant-pagination-total-text {
    white-space: nowrap;
  }

  .phone-number {
    white-space: nowrap;
  }

  div.pic-name {
    white-space: break-spaces;
    margin-left: 1em !important;
    margin-right: 1em !important;
    span {
      max-width: 110px;
    }
  }
}

