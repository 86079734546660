.customer_setting {
  margin: 26px 0;
  &_card_search {
    padding: 20px;
    background-color: @white;
  }
  &_label {
    font-weight: @bold;
    font-size: @sm;
    line-height: @xxl;
    color: @black-2;
  }
  .ant-input {
    height: 44px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-row-middle {
    margin-bottom: 20px;
  }
  .ant-form-item-explain-error {
    align-items: flex-start;
  }
  .ant-col-xl-2 .ant-typography {
    color: @black-2;
  }
  .ant-table-selection-column {
    width: 2%;
  }
  &_list {
    font-weight: @bold;
    font-size: @h2;
    line-height: @line_hight_h2;
    color: @darken-primary !important;
  }
  .list_customer {
    margin-top: 40px;
    .ant-card {
      background: none;
    }
    .custom_table {
      table {
        border-collapse: collapse;
      }
      .ant-table {
        border: none;
        background: none !important;
      }
      .ant-table-thead {
        background: none;
        .ant-table-cell {
          background: none !important;
          border: 1px @primary-color solid;
          color: @black-2;
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          height: 100px;
          background: @white;
          border: 1px @gray-1 solid;
          padding: 0 !important;
          color: @black-2;
          .ant-divider {
            margin: 5px 0px !important;
            border-color: @gray-1 !important;
          }
          > .ant-row {
            padding: 5px;
            padding-bottom: 9px;
          }
          .max_price {
            margin-bottom: 0 !important;
            .currency_symbol {
              margin-right: 10px;
              font-weight: @bold;
              font-size: @sm;
            }
            .ant-col {
              padding: 0 !important;
              .ml-12 {
                margin: 0 !important;
              }
            }
          }
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8) {
            vertical-align: bottom;
          }
        }
        .ant-table-selection-column {
          background: none !important;
          border: none !important;
        }
      }
      .max_amount_input {
        .ant-form-item-label {
          display: flex;
          align-items: center;
          label {
            margin: 0 !important;
          }
        }
        .ant-input {
          height: 100%;
        }
      }
      .text-middle {
        align-items: center;
        height: 100%;
      }
    }
  }
  .customer_classification {
    align-items: center;
  }
  .wrap-text {
    white-space: pre-wrap;
    word-break: break-word;
  }
  .p-5 {
    padding: 5px !important;
  }
  .job_and_region {
    .ant-select-selector {
      height: 44px;
    }
    .ant-select-selection-item {
      line-height: 42px;
    }
    .ant-select-selection-placeholder {
      line-height: 42px;
    }
    &_table {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .auto_field {
        .ant-form-item-control-input-content {
          width: 120px;
        }
        .ant-form-item-control-input {
          min-height: unset;
          height: auto;
        }
        .ant-select-selection-item {
          line-height: unset;
        }
        .ant-select-selection-placeholder {
          line-height: unset;
        }
      }
      button {
        justify-content: center;
      }
      .ant-select-selector {
        border: 1px solid #231815;
        height: 24px;
      }
      .ant-select-arrow {
        color: #231815;
        font-weight: 700;
      }
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    .label {
      font-weight: @bold;
      font-size: @sm;
      line-height: @xxl;
      color: @black-2;
    }
    .ant-select-selector {
      border: 1px solid #231815;
      height: 44px;
    }
    .ant-select-arrow {
      color: #231815;
      font-weight: 700;
    }
  }
  .cell {
    &_space-between {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    &_center {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }
    &_profit_margin {
      height: 100%;
      align-items: flex-end;
    }
    &_price {
      align-items: center;
    }
  }
}
.error_message {
  color: @primary-color;
  font-size: @sm;
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.button_edit {
  border: 1px solid @primary-color;
  color: @primary-color;
  height: 24px;
  padding: 0 17px;
  display: flex;
  align-items: center;
}

.fs-16 {
  font-size: @md !important;
}

@media screen and (max-width: 1200px) {
  .mb-md-0 {
    margin-bottom: 0px !important;
  }
  .mb-md-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .customer_setting {
    margin-inline: -1em;
    &_title {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid @gray-1;
      margin-bottom: 8px;
      padding-bottom: 8px;
    }
    &_display_mobile {
      display: none;
    }
    .customer_classification {
      .customer_setting_label {
        margin-right: 10px;
      }
    }
  }
}
