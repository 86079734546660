.customer_registration {
  min-height: 800px;
  margin-top: 20px;
  .tag_label (@color, @content, @mr: 5px, @pt: 4px) {
    display: inline-block;
    text-align: center;
    width: 26px;
    height: 16px;
    border-radius: 8px;
    font-size: 9px;
    background: @color;
    color: @white;
    padding-top: @pt;
    padding-right: 1px;
    margin-right: @mr;
    content: @content;
    font-family: Yu Gothic !important;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    .tag_label(@primary-color, '必須');
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: none;
  }
  .input_any {
    .ant-form-item-label > label.ant-form-item-no-colon::before {
      .tag_label(@grey-2, '任意' , 5px , 1px);
    }
  }
  .note {
    font-size: 14px;
    color: @black;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 20px;
    h4 {
      font-size: 16px;
      font-weight: 700;
    }
    .ant-typography {
      margin: 0;
    }
  }
  .section_title {
    h5.ant-typography {
      font-size: 16px;
      font-weight: 700;
      color: @darken-primary;
    }
  }
  .address_error {
    white-space: pre-line;
  }
  .infomation_input {
    &_address {
      .ant-form-item-has-error {
        .ant-form-item-explain-error {
          display: none !important;
        }
      }
      .ant-input-status-error {
        border-color: @black !important;
      }
    }
    background: @white;
    padding: 30px 20px 30px 20px;
    input {
      height: 44px;
    }
    .input_email {
      .ant-form-item-label label {
        white-space: break-spaces;
      }
    }
    .btn-search {
      max-width: 170px;
    }
    .price_unit {
      display: flex;
      justify-content: center;
      margin-top: 12px;
      .ant-typography {
        font-size: 14px;
        font-weight: 700;
        color: @black;
      }
    }
    .internal_pic {
      align-items: baseline;
      &_modal {
        &_label {
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
    .job_and_region {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      .ant-select-selection-item {
        line-height: 42px;
      }
      .ant-select-selection-placeholder {
        line-height: 42px;
      }
      .ant-select-selector {
        border: 1px solid #231815;
        height: 44px;
      }
      .ant-select-arrow {
        color: #231815;
        font-weight: 700;
      }
    }
  }
  .form_label {
    .input_label {
      font-size: 14px;
      font-weight: 700;
      color: @black-2;
    }
    .input_label();
    margin-left: 31px;
    &_any {
      .input_label();
      &::before {
        .tag_label(@grey-2, '任意', 5px, 2px);
      }
    }
    &_required {
      display: inline-flex;
      align-items: center;
      .input_label();
      &::before {
        .tag_label(@primary-color, '必須', 5px, 2px);
      }
    }
  }
  .ant-btn-primary {
    height: 44px;
  }
  .submit_button {
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    .section_title {
      padding: 0px 20px;
    }

    .search {
      padding: 30px 15px 30px 15px;
    }
    .infomation_input {
      .input_email {
        .ant-form-item-label label {
          width: auto;
        }
      }
      .price_unit {
        margin-top: 58px !important;
      }
      .internal_pic {
        &_modal {
          &_button {
            min-width: 90px;
          }
        }
      }
    }
  }
}
