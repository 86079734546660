.custom_modal {
  .ant-modal-content {
    border-radius: 0 !important;
  }
  .ant-modal-body {
    padding: 0;
    h1 {
      text-align: left;
      height: 60px;
      padding: 0 36px;
      margin-bottom: 0;
      background: @darken-primary;
      font-size: 16px;
      font-weight: @bold;
      color: @white;
      display: flex;
      align-items: center;
    }
  }

  .ant-modal-close {
    color: @white;

    &:hover {
      color: @white;
    }
  }
}
