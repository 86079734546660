.date_range_picker {
  align-items: baseline;
  .ant-picker {
    border: 1px solid @black;
    .ant-picker-suffix {
      color: @primary-color;
    }
  }
  .ant-form-item-label label {
    margin-bottom: 0 !important;
  }
}

.ant-picker-footer-extra {
  justify-content: center;
  display: flex;
}
