.price-copy-modal {
  padding: 1em;

  .btn-select {
    width: 170px;
  }

  .description {
    font-weight: @medium;
  }

  @media (max-width: 767px) {
    .description {
      font-size: @xs;
    }
  }
}