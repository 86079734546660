.footer {
  font-size: 14px;
  padding: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 60px;
  background: @light-bg;

  .footer-bottom {
    line-height: 60px;
    text-align: center;
    margin-bottom: 0;
    font-size: 12px;
    color: @black;
    font-weight: 400;
  }
}

.background {
  background: @darken-primary;
  .footer-bottom {
    color: @white;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    .footer-bottom {
      font-size: 10px;
    }
  }
}
