.master_registration {
  min-height: 800px;
  margin-top: 20px;
  .ant-upload {
    order: 2;
  }
  .display_center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list_master_registration {
    background: @white;
    padding: 30px 20px 30px 20px;

    .btn-search {
      max-width: 170px;
    }
  }
  .parent_status {
    .display_center();
    .ant-radio-wrapper {
      margin: 0;
    }
  }
  .button_register {
    .ant-btn {
      width: 64px;
      height: 24px;
      .display_center();
      span {
        font-size: 12px;
      }
    }
  }
  .status_row {
    border: none;
    border-top: 1px @gray-1 solid;
  }
  .file_name {
    margin-top: 0;
    > span {
      justify-content: flex-end;
    }
  }
  .item-upload {
    border: 1px @gray-1 solid;
    border-bottom: none;
    padding: 26.5px 26.5px 10px;
    .ant-btn {
      height: 32px;
    }
    .col-error {
      display: flex;
      justify-content: flex-end;
    }
  }
  .price_calculation {
    display: flex;
    align-items: center;
  }
  .not-left {
    border-left: none;
  }
  .text-title {
    color: @black-2;
    font-weight: 700;
    font-size: 16px;
  }
  .btn-download {
    padding-left: 20px;
    .ant-btn {
      height: 24px;
      width: 125px;
      .display_center();
    }
  }
  .ant-upload {
    .ant-btn {
      color: @red-base;
      border: @red-base 1px solid;
      height: 24px;
      width: 125px;
      .display_center();
    }
  }
  .title-upload {
    align-items: center;
    display: flex;
    border-top: 1px @gray-1 solid;
  }
  .note {
    font-weight: 400;
    color: @black;
  }
  .custom_card {
    background: @grey-4;
  }
  .ant-table {
    color: @black;
    font-weight: 400;
    border: none;
  }
  .ant-table-thead {
    .ant-table-cell {
      background: @grey-4;
    }
  }
  .ant-table-row .ant-table-cell {
    padding-top: 25px !important;
  }
  .anticon-upload {
    float: left;
    margin-right: 5px;
  }
  .hidden-max {
    display: none;
  }
  .ant-divider {
    border-top: 1px @gray-1 solid;
  }
  .contain-button {
    button:first-child {
      margin-right: 30px;
    }
  }
  .setting_column {
    display: flex;
    &_title {
      line-height: normal;
    }
    &_btn {
      .ant-btn {
        width: 64px;
        height: 24px;
        .display_center();
        span {
          font-size: 12px;
        }
      }
    }
    &_select {
      .ant-form-item {
        width: 64px;
      }
      .ant-form-item-control-input {
        min-height: unset;
        height: auto;
      }
      .ant-select-selection-item {
        line-height: unset;
      }
      .ant-select-selection-placeholder {
        line-height: unset;
      }
      .ant-select-selector {
        height: 24px;
        border: 1px solid @black;
      }
    }
  }
  .has-select-column {
    padding-left: unset;
  }

  @media (min-width: 992px) {
    .status_row {
      padding-top: 25px;
      padding-left: 20px;
    }
    .price_calculation {
      border-top: 1px @gray-1 solid;
    }
  }
  @media (max-width: 992px) {
    .status_row {
      padding-top: 0;
    }
    .status_row {
      padding: 0;
    }
    .contain-text-title {
      display: none;
    }
    .hidden-max {
      display: flex;
    }
    .item-upload {
      border: none;
      border-top: 1px @gray-1 solid;
    }
    .title-upload {
      border: none;
      border-top: 1px @gray-1 solid;
    }
    .status_row {
      border-top: none;
    }
  }
  .ant-table-cell:last-child {
    .datePicker {
      .display_center();
    }
  }
  .ant-upload-list-item-name {
    padding: 0;
    display: inline-block;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .ant-table-cell {
    max-width: 250px;
  }
  @media (max-width: 1200px) {
    .setting_column {
      &_btn {
        .ant-btn {
          width: 125px;
        }
      }
      &_select {
        .ant-form-item {
          width: 125px;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1400px) {
    .ant-upload-list-item-name {
      max-width: 170px;
    }
  }
  @media (min-width: 768px) {
    .has-select-column {
      padding-left: 26.5px;
    }
    .ant-upload-list-text-container {
      width: 160px;
    }
    .ant-form-item-explain {
      width: 125px;
    }
    .title-upload {
      .text-title {
        margin-top: 18px;
      }
    }
    .contain-file {
      display: flex;
      justify-content: flex-end;
      .ant-form-item-control-input {
        align-items: unset;
      }
    }
    .divider-mobile {
      display: none;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .has-select-column {
      padding-left: unset;
      .ant-upload-list-text-container {
        width: 80px;
      }
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .setting_column {
      &_select {
        padding-left: 10px;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .setting_column {
      &_title {
        display: flex;
        justify-content: flex-end;
      }
      &_select {
        display: flex;
        justify-content: flex-end;
      }
      &_btn {
        padding-left: 20px;
      }
    }
  }
  @media (max-width: 768px) {
    .setting_column {
      margin-top: 10px;
      &_btn {
        .ant-btn {
          width: 64px;
        }
      }
      &_select {
        .ant-form-item {
          width: 64px;
        }
      }
    }
    .col-error {
      position: absolute;
      bottom: -15px;
    }
    .col-item-upload {
      width: 100%;
    }
    .title-upload {
      align-items: flex-start;
      padding-top: 10px;
    }
    .item-upload {
      padding: 0;
      .ant-upload-select {
        position: absolute;
        top: 44px;
        left: -2px;
        z-index: 9;
      }
    }
    .parent_status {
      justify-content: flex-start;
    }
    .list_master_registration {
      margin-right: -15px;
      margin-left: -15px;
    }
    .text-title {
      font-size: 14px;
    }
    .table_button {
      display: flex;
      justify-content: flex-start;
    }
    .ant-upload {
      button {
        font-size: 12px;
        width: 123px;
      }
    }
    .btn-download {
      button {
        font-size: 12px;
        width: 123px;
        margin-top: 2px;
      }
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .status_row {
      align-items: center;
      margin-bottom: 12px;
      .status {
        margin-bottom: 0;
      }
    }
    .contain-button {
      button:first-child {
        margin-right: 10px;
      }
    }
  }
  @media only screen and (min-width: 585px) and (max-width: 768px) {
    .btn-download {
      justify-content: center;
    }
  }
}
.master-registration-modal {
  .ant-modal-confirm-title {
    text-align: center !important;
  }
}
