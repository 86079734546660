.internal_pic_modal {
  .ant-divider {
    background-color: @primary-color;
  }
  .ant-table-content {
    padding: 12px 20px 38px 20px;
    background-color: @container-bg;
    margin: 0 20px;
  }
  .ant-table-tbody {
    background-color: @white;
    color: @black-2;
  }
  .ant-table-thead {
    .ant-table-cell {
      border-bottom: 1px @primary-color solid;
      font-weight: @bold;
      font-size: @sm;
      line-height: @xxl;
      color: @black-2;
    }
    .ant-checkbox {
      display: none;
    }
  }
  .ant-input {
    height: 44px;
  }
  .ant-typography {
    color: @black-2;
  }
  .ant-btn {
    height: 44px;
    font-size: @md;
  }
  &_label {
    font-size: 14px !important;
    font-weight: 700;
  }
  &_input {
    align-items: baseline;
  }
}

@media screen and (max-width: 768px) {
  .internal_pic_modal {
    &_button_setting {
      background-color: @container-bg;
      margin-top: 0 !important;
      padding: 20px;
    }
    .ant-table-content {
      margin: 0;
    }
  }
}
