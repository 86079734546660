.profit_margin_setting {
  .ant-select-selector {
    align-items: center;
  }
  .ant-form-item-no-colon {
    margin: 0 !important;
  }
  .ant-form-item input {
    height: 44px;
  }
  .select-style {
    text-align: center;
    border-right: 1px solid @grey-2;
    .ant-select.ant-select-in-form-item {
      max-width: 86px;
    }
    .ant-select-selection-item {
      text-align: left;
    }
    .ant-select-selector {
      border-color: @black;
      height: 44px;
    }
    .ant-select-arrow {
      color: @black;
    }
  }
  .note {
    margin-bottom: 25px;
    .ant-typography {
      color: @black;
      &:first-child {
        color: @primary-color;
        text-decoration: underline;
      }
    }
    &_block {
      display: block;
    }
    &_title {
      color: @primary-color !important;
    }
  }
  .setting {
    margin-bottom: 30px;
    > div:not(:nth-child(2)) {
      > .ant-col {
        border-top: 1px solid @grey-2;
      }
    }
    .ant-select-show-search {
      .ant-select-arrow {
        color: @black;
        font-weight: 700;
      }
    }
    .number_only_field {
      .ant-typography {
        font-weight: 700;
      }
    }
    .select_unit_price {
      .ant-select-selection-item[title='₫'] {
        font-family: @font-family-vi;
      }
    }
    .ant-form-item-has-error {
      .ant-form-item-explain-error {
        display: none !important;
      }
    }
    .ant-input-status-error {
      border-color: @black !important;
    }
    .new {
      > .ant-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 115px;
        border-top: 1px solid @grey-2;
      }
      &_divider {
        display: none;
      }
    }
    &_label {
      border-left: 1px solid @grey-2;
      > .ant-col {
        min-height: 50px;
        background-color: @gray-1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        &:first-child {
          background-color: @darken-primary;
        }
        &:nth-child(2) {
          justify-content: left;
          .ant-typography {
            margin-left: 15px;
          }
        }
        .ant-typography {
          font-weight: 700;
          white-space: pre-line;
          color: @white;
        }
        &:not(:first-child):not(:nth-child(2)) {
          border-right: 1px solid @grey-2;
        }
      }
    }
    &_input {
      background-color: @white;
      align-items: center;
      border-left: 1px solid @grey-2;
      .label {
        font-weight: 700;
      }
      .font-vi {
        .number_only_field {
          .ant-typography {
            font-family: @font-family-vi;
          }
        }
      }
      .ant-form-item {
        margin: 0;
      }
      > .ant-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 70px;
        padding: 10px;
        &:not(:first-child):not(:nth-child(2)) {
          border-right: 1px solid @grey-2;
        }
      }
      &_new {
        display: flex;
        flex-direction: column;
        > div {
          display: flex;
          width: 100%;
          .ant-typography {
            white-space: nowrap;
          }
          &:first-child {
            margin-bottom: 10px;
          }
        }
        .auto_field {
          width: 100%;
          .ant-select-selector {
            border: 1px solid @black;
          }
        }
      }
      &_select {
        .select-style();
        &_new {
          .select-style();
          border: unset;
        }
      }
    }
    &_action {
      margin-top: 10px;
      &_add {
        margin-right: 20px;
        width: 170px;
        height: 44px;
      }
      &_delete {
        width: 105px;
        height: 44px;
      }
    }
  }
  .mobile {
    display: none !important;
  }
  .profit {
    .ant-typography {
      margin: 0;
    }
    &_row {
      background-color: @white;
      border-top: 1px solid @primary-color;
      border-bottom: 1px solid @primary-color;
      margin-bottom: 20px;
      > .ant-col {
        padding: 20px;
      }
    }
    &_title {
      text-align: right;
      background-color: @main-lighter;
      .ant-typography {
        margin-top: 7px;
        font-weight: 700;
      }
    }
    &_input {
      .ant-form-item {
        margin: 0;
      }
      .ant-form-item-control-input-content {
        .ant-typography {
          font-weight: 700;
        }
      }
    }
    &_select {
      .ant-form-item {
        margin: 0;
      }
      .ant-select-selector {
        border-color: @black;
        height: 44px;
      }
      .ant-select-arrow {
        color: @black;
        font-weight: 700;
      }
    }
    &_note {
      font-size: 12px;
    }
    &_info {
      display: block;
    }
    &_divider {
      margin: 20px 0;
    }
  }
  .action {
    justify-content: center;
    button {
      width: 170px;
      height: 44px;
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
  .api_setting {
    flex-direction: column;
    > div:first-child {
      background-color: @white;
    }
    &_row {
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid @grey-2;
      &:last-child {
        border-bottom: unset;
      }
      .ant-form-item {
        margin: 0;
      }
      > div {
        height: 70px;
        padding: 10px;
        border-right: 1px solid @grey-2;
        &:last-child {
          border-right: unset;
        }
      }
    }
    &_input {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      &_icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-form-item {
        width: 100%;
      }
      &_title {
        margin-right: 5px;
        margin-bottom: 0;
        font-weight: 700;
      }
      &_text {
        width: 100%;
        line-height: 41px;
        border: 1px solid @black;
        text-align: center;
        border-radius: 3px;
      }
    }
    &_select {
      .ant-row {
        align-items: center;
        justify-content: space-between;
      }
      &_currency {
        text-align: center;
      }
      .ant-select-selector {
        border-color: @black;
        height: 44px;
      }
    }
    .input_range_picker {
      .ant-form-item-explain-error {
        display: none !important;
      }
    }
    .ant-input-status-error {
      border-color: @black !important;
    }
    &_label {
      min-height: 50px;
      background-color: @gray-1;
      display: flex;
      align-items: center;
      text-align: center;
      .ant-typography {
        font-weight: 700;
        white-space: pre-line;
        color: white;
        margin-left: 15px;
      }
    }
    &_fields {
      border: 1px solid @grey-2;
      border-bottom: unset;
    }
    .setting_select_api {
      .ant-select-status-error .ant-select-selector {
        border-color: @black !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .profit_margin_setting {
    .desktop {
      display: none !important;
    }
    .mobile {
      display: block !important;
    }
    .setting {
      &_label {
        border: unset;
      }
      &_input {
        border-left: unset;
        border-top: 1px solid @grey-3;
        > .ant-col {
          border: unset !important;
          height: unset;
          label {
            white-space: pre-line;
          }
        }
        &_title {
          font-weight: 700;
        }
        &_select {
          .ant-select.ant-select-in-form-item {
            max-width: unset;
          }
        }
      }
      .new {
        border-top: 1px solid @grey-3;
        > .ant-col {
          height: unset;
        }
        &_divider {
          padding-top: 0;
          div {
            display: block;
            margin: 0;
            border-color: @gray-1;
          }
        }
      }
      &_action {
        justify-content: space-between;
        margin-top: 0;
        padding: 20px 15px;
        background-color: @white;
        border-top: 1px solid @grey-3;
        &_add {
          width: 47%;
          margin-right: 0;
        }
        &_delete {
          width: 47%;
        }
      }
      .select_unit_price {
        align-items: center;
        label {
          height: auto;
        }
      }
    }
    .profit {
      &_title {
        text-align: left;
        .ant-typography {
          margin: 0;
        }
      }
      &_row {
        > .ant-col {
          padding: 20px 15px;
        }
      }
      &_select {
        padding-bottom: 0 !important;
        &:last-child {
          padding-bottom: 15px !important;
        }
      }
      &_info {
        font-size: 12px;
        font-weight: 500;
      }
    }
    .note {
      span {
        font-size: 12px;
      }
    }
    .action {
      button {
        width: 100%;
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
    .api_setting {
      &_input {
        padding: 5px 0;
      }
      .setting_action_add {
        width: 100%;
      }
      &_row {
        .api_setting_select {
          padding-left: 31px;
        }
        > div {
          border-right: unset;
        }
      }
      &_fields {
        border-right: unset;
        border-left: unset;
      }
      .hide_input_mobile {
        display: none;
      }
    }
  }
}
