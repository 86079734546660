.ant-layout-header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  height: 60px;
  @media (max-width: 373px) {
    .ant-btn-link {
      margin-left: -8px !important;
    }
  }
}

img.logo-header {
  width: 85px;
  cursor: pointer;
}
