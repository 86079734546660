.basic_setting_container {
  .form {
    background-color: @white;
    padding: 20px;

    input {
      height: 44px;
    }

    &_label_wrapper {
      display: flex;
      align-items: center;
    }
    .input_label {
      font-size: 14px;
      font-weight: 700;
      color: @black-2;
    }

    &_label {
      .input_label();
      margin-left: 31px;
      &_any {
        .input_label();
        &::before {
          .tag_label(@grey-2, '任意', 5px, 2px);
        }
      }
      &_required {
        display: flex;
        .input_label();
        &::before {
          .tag_label(@primary-color, '必須', 5px , 2px);
        }
      }
    }

    &_link {
      color: @link-blue;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
    }

    &_divider {
      border-top: 1px solid @gray-1;
      margin: 20px 0;
    }

    &_checkbox_group {
      text-align: end;
      .ant-checkbox-wrapper:first-child {
        margin-right: 40px;
      }
    }

    &_radio_group {
      .ant-radio-wrapper:last-child {
        margin-right: 0;
      }
      .ant-form-item-no-colon {
        margin-left: 31px;
      }
    }

    &_person {
      height: 350px;
      overflow-y: auto;
      overflow-x: hidden;
      .ant-form-item-label {
        padding-left: 36px;
        label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          display: none;
        }
      }

      &_name {
        .ant-form-item-label {
          padding-left: 0;
        }
      }
      &_required {
        align-items: baseline;
        .form_label {
          margin-left: 0;
        }
        .form_label::before {
          .tag_label(@primary-color, '必須', 7px, 2px);
        }
      }
      &_label {
        display: flex;
        align-items: center;
      }
      &_sub_label {
        .form_label {
          margin-left: 33px;
        }
      }
      &_row {
        align-items: baseline;
        justify-content: end;
        .ant-row {
          align-items: baseline;
        }
        .ant-form-item {
          margin-bottom: 20px;
        }
      }
    }
    &_select {
      width: 100%;
      margin-bottom: 20px;
      .ant-form-item-no-colon {
        margin-left: 31px;
      }
      .ant-select-selector {
        border-color: @black;
        height: 44px;
        display: flex;
        align-items: center;
      }
    }
    &_hours {
      align-items: baseline;
      &_middle {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        font-size: 14px;
        font-weight: 700;
      }
    }
    &_business_day {
      align-items: baseline;
      .form_label {
        margin-left: 9px;
      }
      &_text {
        margin-top: 10px;
      }
      &_note {
        margin-left: 9px;
        color: @grey-2;
        font-size: 12px;
        font-weight: 400;
      }
      .ant-form-item {
        margin-bottom: 0;
      }
    }
    &_upload {
      height: 44px;
      margin-bottom: 30px;
      .ant-upload-select-picture-card {
        border: unset;
        background-color: unset;
        height: auto;
        width: auto;
        float: left;
      }
      .ant-upload-list-picture-card-container {
        width: 44px;
        height: 44px;
        margin: 0;
        .ant-upload-list-item {
          padding: 1px;
        }
        .anticon-delete {
          margin-left: 6px;
        }
        .ant-upload-list-item-error {
          border-color: #d9d9d9;
        }
      }
    }
    &_button {
      height: 44px;
      font-size: 16px;
    }
    &_upload_label {
      display: flex;
      align-items: center;
      height: 44px;
      .form_label {
        margin-bottom: 0;
      }
    }
    &_textarea {
      textarea {
        height: 122px;
        resize: none;
        line-height: 22px;
      }
    }
  }

  .form_person {
    .field_disabled {
      pointer-events: none;
      input {
        border-color: #949495 !important;
      }
    }
    .group_field_row .group_field_button .anticon {
      font-size: 16px !important;
    }
    .form_responsible_person {
      align-items: baseline;
    }
  }

  .submit_button {
    display: flex;
    justify-content: center;
    button {
      width: 170px;
      height: 44px;
      margin-bottom: 89px;
    }
  }

  .note {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
}

.tag_label (@color, @content, @mr: 5px, @pt: 4px) {
  display: inline-block;
  text-align: center;
  width: 26px;
  height: 16px;
  border-radius: 8px;
  font-size: 9px;
  background: @color;
  color: @white;
  padding-top: @pt;
  margin-right: @mr;
  content: @content;
  font-family: Yu Gothic !important;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  .tag_label(@primary-color, '必須');
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: none;
}

.input_any {
  .ant-form-item-label > label.ant-form-item-no-colon::before {
    .tag_label(@grey-2, '任意' , 5px , 1px);
  }
}

@media screen and (max-width: 1400px) {
  .basic_setting_container {
    .suggestion_condition {
      .ant-radio-wrapper {
        width: calc((100% - 8px) / 2);
        &:first-child {
          margin-right: 8px !important;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .basic_setting_container {
    .form {
      &_checkbox_group {
        .ant-checkbox-wrapper {
          margin-right: 0;
          &:first-child {
            margin-right: 0;
          }
        }
      }
      &_wrapper {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .basic_setting_container {
    .form {
      &_label {
        margin-left: 0px;
      }

      &_checkbox_group {
        text-align: start;
        padding-left: 25%;
        .ant-checkbox-wrapper:first-child {
          margin-right: 29px;
        }
      }
      &_person {
        height: 390px;
        .ant-form-item-label {
          padding-left: 0;
        }
      }
      &_radio_group {
        .ant-radio-wrapper:first-child {
          margin-right: 30px;
        }
        .ant-form-item-no-colon {
          margin-left: 0;
        }
      }
      &_select {
        .ant-form-item-no-colon {
          margin-left: 0;
        }
      }
      .form_responsible_person {
        margin-bottom: 0px;
      }
      &_textarea {
        textarea {
          height: 168px;
        }
      }
    }

    .note {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .form_checkbox_group {
    .ant-form-item-control-input-content {
      > .ant-row {
        > .ant-col {
          padding-left: 0 !important;
          padding-right: 0 !important;
          .ant-radio-wrapper {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}
