.tax_exchange_setting {
  .radio_tax {
    margin-bottom: 12px;
    flex-direction: column;
    .ant-row {
      margin-bottom: 0px;
    }
    .note_tax {
      white-space: break-spaces;
    }
  }
  .tax_exchange {
    padding: 20px;
    padding-bottom: 30px;
    margin-bottom: 40px;
    background: @white;
    &_title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: @black-2;
    }
    &_list {
      max-height: 285px;
      overflow: auto;
    }
    .ant-divider {
      margin: 10px 0px 20px;
      border-color: @gray-1;
    }
    .dummy_text {
      font-size: @sm;
      font-weight: @medium;
      color: @black-2;
      line-height: 44px;
    }
    .select-currency-unit {
      .ant-form-item-explain-error {
        display: none !important;
      }
    }
    .message_error {
      transform: none !important;
    }
    .tax_setting {
      &_item {
        display: flex;
        flex-direction: row;
        width: 100%;
        .ant-form-item-has-error {
          .ant-form-item-explain-error {
            display: none !important;
          }
        }
      }
      &_checkbox {
        .ant-checkbox-group {
          display: flex;
          align-items: baseline;
          .ant-checkbox-wrapper {
            > span {
              width: max-content;
            }
          }
        }
        &_item {
          display: flex;
          flex-direction: row;
          margin-right: 30px;
          align-items: center;
          &:last-child {
            margin-right: 0;
          }
          &_select {
            width: 160px;
            margin-bottom: 0;
            margin-right: 10px;
          }
        }
      }
      &_option {
        flex-flow: row;
      }
    }
    .setting_action {
      padding-bottom: 10px;
      button {
        width: 105px;
        height: 44px;
      }
      &_add {
        margin-right: 10px;
      }
    }
    .fractional_tax {
      align-items: center;
      &_title {
        .ant-typography {
          font-size: 14px;
          font-weight: 700;
          color: @black-2;
        }
      }
      &_select {
        width: 190px;
      }
    }
  }
  .label_input {
    font-size: @sm;
    font-weight: @bold;
    color: @black-2;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .label_tax {
    align-items: baseline;
    padding-top: 10px;
  }
  .currency_transfers {
    .btn_setting_wrapper {
      column-gap: 10px;
    }
    .unit_options {
      .ant-form-item-explain-error {
        display: none !important;
      }
    }
    .label_input {
      line-height: 44px;
    }
  }
  .title_text {
    display: block;
    font-size: @sm;
    font-weight: @bold;
    color: @black-2;
  }
  .fw-medium {
    font-weight: @medium;
  }
  .btn_setting_wrapper {
    display: flex;
    justify-content: center;
    .ant-btn-lg {
      min-width: 105px;
      height: 44px;
      background-color: @primary-color;
      color: @white;
      border-color: @primary-color;
      font-size: @md;
      font-weight: @bold;
    }
    .btn_setting_170 {
      width: 170px;
    }
    .ant-btn[disabled] {
      background: @dark-disabled;
      border-color: @dark-disabled;
    }
  }
  .justify-start {
    justify-content: start;
  }
  .ant-select {
    width: 105px;
    height: 44px;
    .ant-select-single,
    .ant-select-selector {
      height: 44px !important;
      line-height: 44px !important;
      border-color: @black;
    }
    .ant-select-selection-item {
      height: 44px !important;
      line-height: 44px !important;
    }
  }
  .message_error {
    color: @primary-color;
    font-size: @sm;
    font-weight: @medium;
    display: flex;
    align-items: center;
    column-gap: 5px;
    transform: translateY(-10px);
  }
  .ant-form-item-no-colon {
    height: 44px;
  }
  .ant-input {
    height: 44px;
  }
  .my-20 {
    margin: 20px 0px !important;
  }
  .mb-10 {
    margin-bottom: 10px !important;
  }
  .mb-30 {
    margin-bottom: 30px !important;
  }

  @media screen and (max-width: 1200px) {
    .currency_transfers {
      .label_input {
        margin-top: 10px;
        line-height: 22px;
      }
    }
    .label_tax {
      padding-top: 0px;
    }
  }

  @media screen and (max-width: 992px) {
    .label_tax {
      padding-top: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    .tax_exchange {
      .divider_desktop {
        display: none;
      }
      .setting_action {
        margin-top: 20px;
      }
      padding-inline: 10px;
      &_list {
        max-height: 330px;
        .tax_setting_item:last-child {
          margin-bottom: 0;
        }
      }
      .label_input {
        margin-bottom: 0;
        line-height: 44px;
      }
      .radio_tax {
        margin-bottom: 0;
      }
      .tax_setting {
        &_checkbox {
          > .ant-form-item {
            margin-bottom: 0;
          }
          .ant-checkbox-group {
            flex-direction: column;
            label > span:last-child {
              width: 100%;
              padding-right: 0;
            }
          }
          &_normal {
            width: 100%;
            border-top: 1px solid @gray-1;
            margin-right: 0;
            &:first-child {
              border-top: unset;
            }
            .ant-typography {
              margin: 22.5px 0px;
              display: flex;
            }
          }
          &_item_select {
            margin-right: 0;
          }
        }
        &_option {
          width: 100%;
          justify-content: space-between;
          border-top: 1px solid @gray-1;
          flex-direction: row;
          align-items: center;
          .ant-typography {
            margin: 22.5px 0px;
            display: flex;
          }
        }
        &_item {
          border-bottom: 1px solid @gray-1;
          margin-bottom: 20px;
        }
      }
      .setting_action {
        justify-content: space-between;
        button {
          width: calc(50% - 10px);
        }
      }
      .fractional_tax {
        &_title {
          width: 100%;
        }
        &_select {
          width: 50%;
        }
      }
    }
    .currency_transfers {
      padding-inline: 10px;
      .divider_top {
        border-top: 1px solid @gray-1;
        padding-top: 10px;
      }
      .btn_setting_wrapper {
        column-gap: 0;
        justify-content: space-between;
        .ant-btn {
          width: calc(50% - 10px);
        }
      }
    }
    .currency_unit {
      padding-inline: 10px;
      border-top: 1px solid @gray-1;
      padding-top: 10px;
      margin-top: 10px;
    }
    .label_tax {
      padding-top: 10px;
    }
  }
}
