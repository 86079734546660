.product_search {
  &_label {
    font-size: @sm;
    font-weight: @bold;
  }
  &_title {
    color: @darken-primary;
    font-size: @md;
    font-weight: @bold;
    margin-top: 20px;
  }
  &_item {
    .ant-radio-wrapper {
      margin-bottom: 23px;
      width: 25%;
      margin-right: 0;
    }
  }
  &_button {
    position: absolute;
    bottom: 20px;
    width: 170px;
    height: 44px;
  }
  &_list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 450px);
  }
  .ant-form-item-label {
    label {
      align-items: flex-start;
    }
  }
  .ant-form-item-control-input {
    align-items: flex-start;
  }

  &_shape {
    .ant-form-item-control {
      height: 44px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.quotation {
  .search {
    button {
      height: 44px;
    }
    background-color: @white;
    padding: 30px 20px 40px 20px;
    .custom_checkbox {
      .ant-checkbox-wrapper-in-form-item {
        span:last-child {
          word-break: break-word;
        }
      }
    }
    &_group {
      > div {
        &:first-child {
          padding-right: 5px;
        }
        &:last-child {
          padding-left: 5px;
        }
      }
    }
    .ant-checkbox + span {
      padding-right: 0;
    }
    .ant-form-item-label > label {
      height: auto;
      margin-bottom: 0;
    }
    h5.ant-typography {
      font-size: 14px;
      color: @black-2;
      margin-bottom: 0;
      font-weight: 700;
    }
    &_checkbox {
      &_product {
        .ant-checkbox-group {
          padding-left: 8px;
        }
      }
    }
    &_radio {
      &_quotation {
        padding-left: 8px;
        align-items: baseline;
        .ant-form-item-label label {
          font-weight: 400;
        }
      }
    }
    &_input {
      width: 100%;
    }
    &_title {
      &_customer {
        &_mobile {
          display: none;
          &_desktop {
            display: block;
          }
        }
      }
    }
    &_column {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_row {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      &_button {
        text-align: right;
      }
      &_order {
        align-items: center;
        h5 {
          align-items: baseline;
          margin-bottom: 0;
          color: @black-2;
        }
      }
      &_sub {
        align-items: center;
        justify-content: space-between;
        h5 {
          align-items: baseline;
          margin-bottom: 0;
          color: @black-2;
        }
      }
    }
    .page_size {
      align-items: center;
      h5 {
        max-width: 105px;
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }
    &_divider {
      margin-top: 0;
      margin-bottom: 30px;
      border-color: @gray-1;
    }
    &_button {
      text-align: center;
      button {
        width: 170px;
        height: 44px;
      }
    }
    .ant-select-selector {
      border-color: @black;
    }
  }
  &_title {
    color: @darken-primary !important;
    margin-bottom: 0 !important;
  }
  &_action {
    margin: 10px 0 20px;
    &_button {
      margin-top: 10px;
      margin-left: 10px;
      height: 44px;
      &:first-child {
        min-width: 170px;
        margin-left: 0;
      }
      &:last-child {
        min-width: 170px;
      }
    }
  }

  &_note {
    margin-bottom: 16px;
    .ant-typography {
      color: @black-2;
      &:nth-child(2) {
        color: @primary-color;
      }
      &:nth-child(3),
      &:last-child {
        display: block;
        margin-top: 10px;
      }
    }
  }

  &_text {
    text-align: center;
    margin-bottom: 50px !important;
  }

  .custom_table {
    .ant-table-placeholder {
      height: 40px;
      .ant-table-cell {
        border-color: @light-bg !important;
      }
    }
    .ant-row {
      justify-content: center;
    }
    .ant-table-selection-column {
      width: 2%;
    }
    .ant-table-thead {
      .ant-table-cell {
        border: 1px @primary-color solid;
        color: @black-2;
        font-weight: 700;
        background-color: @light-bg;
      }
      tr > th {
        text-align: center;
        &:nth-child(2),
        &:nth-child(7) {
          white-space: pre-line;
        }
      }
    }
    .ant-table {
      border: none;
      table {
        text-align: center;
        border-collapse: collapse;
      }
    }
    .ant-table-tbody {
      .member {
        background-color: @white !important;
        &:first-child {
          border-bottom: 1px solid @gray-1;
        }
      }
      .purchase {
        border-bottom: 1px solid @gray-1;
      }
      .ant-table-cell {
        border: 1px @gray-1 solid;
        color: @black-2;
      }
      tr > td:first-child {
        background-color: @light-bg;
        border: 1px solid @light-bg;
        border-right-color: @gray-1;
      }
      tr > td:not(:first-child) {
        background-color: @white;
      }
      tr > td:last-child {
        white-space: pre-line;
      }
      tr > td > div:nth-child(even) {
        background-color: @light-bg;
      }
      tr > td {
        .row_item {
          height: 40px;
          align-items: center;
          text-overflow: ellipsis;
          &_link {
            color: @link-blue;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(9) {
          .row_item {
            min-height: calc(160px / 3);
            height: auto;
          }
        }
        &:nth-child(7) {
          .row_item {
            height: 80px;
          }
        }
      }

      tr:not(:first-child) > td {
        padding: 0;
        > div {
          padding: 0 8px;
        }
      }

      tr > td:nth-child(6) {
        span {
          max-width: 200px;
        }
      }
      .ant-select-selector {
        border-color: @black;
        width: 133px;
      }
    }
    .ant-table-pagination-right:last-child {
      .ant-pagination-total-text {
        .ant-space-item:not(:nth-child(6)):not(:last-child) {
          display: none;
        }
      }
      .ant-pagination-item {
        background-color: unset;
      }
    }
    .ant-table-pagination-right:first-child {
      .ant-pagination-item {
        background-color: unset;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product_search {
    .ant-radio-wrapper {
      margin-right: 0;
      width: 50%;
    }
    &_shape {
      display: flex;
      .ant-radio-wrapper {
        width: unset;
      }
    }
    &_list {
      max-height: calc(100vh - 460px);
    }
    &_category {
      .ant-form-item-control {
        .ant-radio-wrapper {
          width: 50%;
        }
      }
    }
  }

  .quotation {
    .search {
      &_group {
        > div {
          padding: 0 !important;
        }
      }
      &_checkbox {
        &_customer {
          display: flex;
          flex-direction: row-reverse;
        }
      }
      .note(@top: 0) {
        font-size: 12px;
        position: absolute;
        top: @top;
        right: 0;
      }
      &_label {
        .note();
      }
      &_label-right {
        .note(35px);
      }
      &_row {
        position: relative;
        &:first-child {
          align-items: flex-start;
        }
        .specific_customer_flag {
          .ant-form-item-control-input {
            min-height: unset;
          }
        }
        &_sub {
          &:first-child {
            align-items: unset;
          }
        }
      }
      &_title {
        &_customer {
          &_desktop {
            display: none;
          }
          &_mobile {
            display: block;
            margin-bottom: 0 !important;
          }
        }
      }
      &_radio {
        &_quotation {
          padding-left: 0;
        }
      }
      .page_size {
        h5 {
          max-width: 100%;
        }
      }
      &_button {
        display: flex;
        justify-content: space-between;
        button {
          width: 47%;
        }
      }
    }
    &_note {
      .ant-typography {
        font-size: 12px;
      }
    }
    .custom_table {
      .ant-table-pagination-right:last-child {
        .ant-pagination-total-text {
          width: auto;
          .ant-space-item:last-child {
            display: block;
          }
        }
      }
    }
    &_action {
      justify-content: space-around;
      &_button {
        min-width: 45%;
        margin-left: 0;
        &:first-child,
        &:last-child {
          min-width: 45%;
        }
      }
    }
    .note > div {
      margin-top: 90px !important;
      padding: 10px;
      font-weight: 400;
    }
    .all {
      padding: 10px;
      align-items: center;
      &_checkbox {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
      &_action {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        &_note {
          margin-left: 5px;
          &_last {
            display: none;
          }
        }
        &_button {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 9px;
          padding: 5px 7px;
          width: 56px;
          .anticon {
            font-size: 14px;
          }
          span:last-child {
            margin-left: 0;
          }
        }
      }
    }
    .order {
      &_desktop {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .product_modal {
    padding: 0 !important;
  }
}

@media screen and (max-width: 360px) {
  .product_search {
    &_list {
      max-height: calc(100vh - 530px);
    }
  }
}
