.category {
  &_product_item {
    width: 25%;
  }
  &_link_product {
    color: @link-blue;
    padding: 7px 10px;
    span {
      text-decoration: underline;
    }
  }
}
.tooltip_description {
  max-width: 250px;
}
.product_tooltip_des {
  padding: 0px 10px;
}
