.user_registration {
  &_modal {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 5px 20px;
    .ant-form-item-label {
      white-space: pre-line;
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
        &::before {
          display: inline-block;
          text-align: center;
          width: 26px;
          height: 16px;
          border-radius: 8px;
          font-size: 9px;
          background: @primary-color;
          color: @white;
          padding-top: 3px;
          padding-right: 1px;
          margin-right: 5px;
          content: '必須';
        }
        &::after {
          display: none;
        }
      }
    }
    .ant-form-item {
      margin-bottom: 20px;
    }
    button {
      height: 44px;
      width: 170px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &_note {
    div.ant-typography {
      margin-bottom: 0;
    }
    margin-bottom: 20px;
  }

  &_search {
    background-color: @white;
    padding: 20px 20px 30px 20px;
    .ant-form-item-no-colon {
      white-space: pre-line;
      margin-bottom: 0 !important;
    }
    &_note {
      margin-top: 10px;
    }
    &_checkbox {
      margin-bottom: 0;
      .ant-checkbox-group-item {
        width: 100px;
      }
    }
    .divider {
      border-color: @gray-1;
    }
  }
  .table {
    .button_countdown,
    .button_edit {
      border: 1px solid @primary-color;
      color: @primary-color;
      height: 24px;
      padding: 0 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-statistic-content {
        display: flex;
        .ant-statistic-content-value {
          font-size: 14px;
          color: @white;
        }
      }
    }
    .ant-btn[disabled] {
      color: @white !important;
    }
    margin-top: 75px;
    .ant-table-empty {
      border: none;

      .ant-table-thead {
        display: none;
      }

      .ant-table-expanded-row-fixed {
        font-weight: bold;
        color: @black;
        text-align: center;
      }
    }
    &_action {
      display: flex;
      justify-content: flex-end;
    }
    &_button {
      display: flex;
      justify-content: space-between;
    }
    .ant-card-bordered {
      background-color: unset;
    }
    &_title {
      .ant-typography {
        font-size: 16px;
        font-weight: 700;
        &:first-child {
          color: @primary-color;
          text-decoration: underline;
        }
      }
    }
    &_button {
      button {
        height: 44px;
        margin-left: 20px;
      }
    }
    &_header {
      align-items: center;
    }
    .divider {
      border-color: @grey-2;
    }
    .custom_table {
      margin-top: 10px;
      margin-bottom: 0;
      .ant-table-pagination-right:last-child {
        .ant-pagination-total-text {
          .ant-space-item:not(:nth-child(6)):not(:last-child) {
            display: none;
          }
        }
        .ant-pagination-item {
          background-color: unset;
        }
      }
      .ant-table-pagination-right:first-child {
        .ant-pagination-item {
          background-color: unset;
        }
      }
      td {
        height: 60px;
      }
      .ant-table {
        border: none;
      }
      .ant-table-thead {
        tr > th {
          background-color: @light-bg;
          &:nth-child(2) {
            text-align: left;
          }
          &::before {
            display: none;
          }
        }
      }
      .ant-table-tbody {
        tr > td {
          background-color: @white;
          &:nth-child(2) {
            text-align: left;
          }
        }
      }
    }
  }

  .button {
    height: 44px;
  }
}

@media screen and (max-width: 992px) {
  .user_registration {
    .table {
      &_title {
        text-align: center;
      }
      &_button {
        button {
          margin-left: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ant-table-pagination-right:last-child {
    .ant-pagination-total-text {
      width: unset;
    }
  }
  .user_registration {
    &_modal {
      .ant-form-item-label {
        white-space: unset;
        .ant-form-item-required {
          margin: 0 !important;
        }
      }
    }
    &_note {
      div.ant-typography {
        font-size: 12px;
      }
      h5.ant-typography {
        font-size: 14px;
      }
    }
    &_search {
      padding-right: 15px;
      padding-left: 15px;
      .ant-form-item-no-colon {
        white-space: unset;
      }
      &_note {
        margin-top: 0;
        text-align: right;
      }
    }
    .table {
      margin: 30px 0;
      &_action {
        flex-direction: column;
      }
      &_button {
        flex-wrap: wrap;
        justify-content: center;
        button {
          width: fit-content;
        }
        &:first-child {
          button {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
