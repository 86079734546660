.plugin_management {
  .btn-update {
    width: 170px;
    height: 44px;
    font-size: 16px;
  }
  .contain-btn-update {
    display: flex;
    justify-content: center;
  }

  .contain-button-delete {
    display: flex;
    justify-content: left;
    align-items: center;
    .ant-btn {
      width: 105px;
      height: 38px;
      font-size: 16px;
    }
  }
  .contain-button {
    display: flex;
    justify-content: center;
  }
  .create_plugin {
    background: @white;
    padding: 30px 20px 30px 20px;
  }
  .ck-toolbar {
    display: none;
  }
  .ck-editor__editable_inline {
    min-height: 180px;
    border: 1px @black solid !important;
  }
  .table {
    .btn-delete {
      color: @primary-color;
      border: 1px @primary-color solid;
      span {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  .col-type-plugin {
    .ant-radio-group {
      margin-top: 10px;
    }
    .ant-radio-wrapper {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .text-area .ant-input {
    min-height: 180px;
    border: 1px @black solid !important;
  }
  .ant-table-cell {
    max-width: 245px;
    font-weight: 400;
    color: @black-2;
  }

  .tag_label (@color, @content, @mr: 5px, @pt: 4px) {
    display: inline-block;
    text-align: center;
    width: 26px;
    height: 16px;
    border-radius: 8px;
    font-size: 9px;
    background: @color;
    color: @white;
    padding-top: @pt;
    padding-right: 1px;
    margin-right: @mr;
    content: @content;
    font-family: Yu Gothic !important;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    .tag_label(@primary-color, '必須');
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: none;
  }
}
