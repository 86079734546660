.pdf_registration_container {
  margin-inline: auto;
  margin-top: 20px;
  margin-bottom: 85px;
  .ant-form-item-no-colon {
    margin-bottom: 0 !important;
  }
  .description_text {
    margin: 20px 0;
    font-size: @sm;
    font-weight: @normal;
    white-space: pre-line;
  }
  .upload_section {
    .ant-form-item {
      margin-bottom: 0;
      align-items: center;
    }
    .divider {
      margin: 0;
    }
    .ant-select-selection-item[title='Tiếng Việt'] {
      font-family: @font-family-vi;
    }
  }
  .file_name {
    margin-top: 0;
  }
  .ant-upload-list-text-container {
    display: none;
  }
  .btn {
    &_upload {
      width: 170px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: @primary-color;
      color: @primary-color;
      font-weight: @bold;
      font-size: @md;
    }
    &_submit {
      width: 170px;
      height: 44px;
      background: @primary-color;
      color: @white;
      font-weight: @bold;
      border-radius: 3px;
      font-size: @md;
    }
    &_update_list {
      min-width: 105px;
      width: auto;
      height: 24px;
      line-height: @md;
      background: @primary-color;
      color: @white;
      font-weight: @bold;
      border-radius: 3px;
      font-size: @xs;
    }
  }
  .text {
    &_primary {
      color: @primary-color;
    }
  }

  .upload_wrapper {
    margin-bottom: 20px !important;
    .file_name_section {
      color: @black-2 !important;
      font-size: @sm;
      font-weight: @normal;
      text-decoration: underline;
    }
    .ant-error-message {
      display: none;
    }
  }
  .display_order_section {
    margin-top: 30px;
    border: 1px solid @grey-2;
    border-inline: none;
    padding: 20px 0;
    .ant-form-item {
      align-items: center;
      margin-bottom: 0;
    }
    .ant-radio {
      color: @black;
    }
    .display_order_wrapper {
      .display_order_title {
        font-size: @sm;
        font-weight: @bold;
        color: @black-2;
        margin-bottom: 0;
        margin-right: 45px;
      }
    }
  }
  .ant-select {
    width: 105px;
    height: 44px;
    .ant-select-single,
    .ant-select-selector {
      height: 44px !important;
      line-height: 44px !important;
    }
    .ant-select-selection-item {
      height: 44px !important;
      line-height: 44px !important;
    }
  }
  .btn_registration {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .list_upload_section {
    .ant-table {
      color: @black-2;
    }
    .ant-table-thead > tr > th {
      color: @black-2;
      font-size: @sm;
      font-weight: @bold;
    }
  }
  .error_message {
    color: @primary-color;
    font-size: @sm;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
}

@media screen and (max-width: 768px) {
  .pdf_registration_container {
    margin-bottom: 30px;
    .upload_section {
      .ant-card-body {
        padding: 20px 15px;
      }
      .upload_wrapper {
        align-items: flex-end;
        margin-bottom: 0 !important;
        .text_primary {
          font-size: @xs !important;
        }
      }
    }
    .display_order_section {
      padding: 10px 0;
      margin-top: 20px;
      .display_order_wrapper {
        align-items: flex-end;
        margin-inline: 15px;
      }
      .ant-form-item-control-input {
        min-height: 0;
      }
    }
    .list_upload_section {
      .btn_delete_list {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .pdf_registration_container {
    .display_order_section {
      .btn_update_list {
        min-width: 75px !important;
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .pdf_registration_container {
    width: 100%;
    margin-bottom: 30px;
    .description_text {
      margin: 20px 15px;
      font-size: @xs !important;
      font-weight: @medium;
    }
    .btn_upload {
      width: auto;
    }
    .btn_registration {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .list_upload_section {
      margin-left: 15px;
    }
  }
}
