.reset_pass {
  width: 500px;
  padding: 70px 50px 70px 50px;
  margin-top: 198px;
  margin-bottom: 77px;
  background: @white;
  &_logo_wrapper {
    text-align: center;
    cursor: pointer;
  }
  &_logo {
    width: 100%;
    margin-bottom: 70px;
  }
  button {
    span {
      font-size: 16px;
    }
  }
  .ant-form-item-explain-error {
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .reset_pass {
    padding: 70px 15px 70px 15px;
  }
}
