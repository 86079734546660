.customer-search-form {
  margin-top: 1em;

  @media (max-width: 767px) {
    .phone-number {
      padding-right: 20px;
    }
    .fax-number {
      padding-left: 20px;
    }

    .form-item-label {
      margin-bottom: 0px !important;
    }
    .ant-form-item-label {
      label {
        margin-bottom: 0px !important;
      }
    }
  }
  @media (min-width: 768px) {
    .fax-number {
      .ant-form-item-label-left {
        padding-left: 20px;
      }
    }
  }

  .form-item-label {
    color: @black-2;
    height: 38px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-weight: @bold;
  }

  .partial-label {
    height: 38px;
    display: flex;
    align-items: center;
    padding-left: 1em;
  }

  .btn-search {
    width: 170px;
  }

  .form-title{
    font-size: @md;
    font-weight: @bold;
  }
}
