.confirm_modal {
  .ant-modal-confirm-body {
    text-align: center;
    margin: 24px 0 42px;
    .ant-modal-confirm-title {
      font-weight: 700;
      font-size: 20px;
    }
  }

  .ant-modal-confirm-btns {
    display: flex;
    justify-content: space-between;
    float: none;
  }
}

.normal_modal {
  .title {
    font-weight: 700;
    font-size: 32px;
  }
}