@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import '_variables';
@import '_container';
@import '_common';
@import '_fonts';
@import 'components/_button';
@import 'components/_customModal';
@import 'components/_formItem';
@import 'components/_header';
@import 'components/_header_restrict';
@import 'components/_menu';
@import 'components/_group_field';
@import 'components/_topNav';
@import 'components/_topSearch';
@import 'components/_loading';
@import 'components/_footer';
@import 'components/_custom_tab';
@import 'components/_custom_table';
@import 'components/_custom_calendar';
@import 'components/_custom_description.less';
@import 'components/_custom_card';
@import 'components/_list';
@import 'components/_menu_mobile.less';
@import 'components/_fileupload_field';
@import 'components/_row_detail.less';
@import 'components/_modal_internal_pic.less';
@import 'components/_custom_modal';
@import 'components/_modal_notification_setting';
@import 'components/_modal_destination';
@import 'components/_date_range_picker.less';
@import 'components/_number_only_field.less';
@import 'components/_product.less';
@import 'pages/_sign_in';
@import 'pages/_sign_up';
@import 'pages/_forgot_password';
@import 'pages/_reset_password';
@import 'pages/_home';
@import 'pages/_notification_setting';
@import 'pages/_basic_setting';
@import 'pages/_registration_master';
@import 'pages/_quotation.less';
@import 'pages/_plugin_management';
@import 'pages/_pdf_registration.less';
@import 'pages/_registration_master';
@import 'pages/_plugin_management';
@import 'pages/_pdf_registration';
@import 'pages/_customer_registration';
@import 'pages/_customer_setting';
@import 'pages/_user_registration_change';
@import 'utils/_modal_confirm';
@import 'pages/_customer_delivery_setting';
@import 'pages/_profit_margin_setting';
@import 'pages/_customer_search_table';
@import 'pages/_customer_search_form';
@import 'pages/_customer_modal';
@import 'pages/_customer_delivery_registration_modal';
@import 'pages/_price_copy_modal';
@import 'pages/_tax_exchange_setting';

@primary-color: #ED1C24;@border-radius-base: 3px;