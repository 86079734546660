.confirm_modal {
  .ant-modal-confirm-title {
    font-size: @md !important;
    white-space: pre-line;
    text-align: left;
  }
  .btn_cancel {
    font-weight: @bold !important;
  }
  .btn_cancel:hover {
    background-color: @primary-color;
    border-color: @primary-color;
  }
  .ant-modal-confirm-btns {
    flex-direction: row-reverse;
    column-gap: 20px;
  }
}
