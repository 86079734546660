.file_name {
  margin-top: 20px;
  .ant-tooltip-inner {
    display: none;
  }

  span {
    display: flex;
    .ant-upload-list {
      .ant-upload-list-item-card-actions {
        display: none;
      }

      .ant-upload-text-icon {
        display: none;
      }
    }

    .ant-upload-list-item {
      pointer-events: none;
    }

    .ant-upload-list-item-name {
      color: @link-blue;
      text-decoration: underline;
    }
  }

  span {
    display: flex;
    align-items: center;
    gap: 13px;
  }

  .ant-upload-list-item {
    margin-top: 0;
  }

  .ant-upload-list-item-name {
    padding: 0;
    display: inline-block;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  @media (min-width: 768px) {
    .ant-upload-list-item-name {
      max-width: 550px;
    }
  }
}
